.DayPicker-wrapper {
    outline: none;
    border: 1px solid #919191;
}

.DayPicker-Day--selectedRange {
    background-color: #EA4E26 !important;
}

.DayPicker-Day--selectedRange.DayPicker-Day--outside {
    background-color: #EA4E2699 !important;
}

.DayPicker-Caption[role=heading] {
    text-transform: capitalize; /* Months */
}
