body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body.login-content {
  height: 100vh;
}

.login-content div#root {
  height: 100%;
}
a {
  text-decoration: none;
  color: black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
